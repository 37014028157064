import { Injectable } from '@angular/core';

@Injectable()
export class JsonKit {
    public stringify(obj: any): string {
        var cache = [];
        var str = JSON.stringify(obj, function (key, value) {
            if (typeof value === 'object' && value !== null) {
                if (cache.indexOf(value) !== -1) {
                    // Circular reference found, discard key
                    return;
                }
                // Store value in our collection
                cache.push(value);
            }
            return value;
        });
        cache = null; // Enable garbage collection 
        return str;
    }

    public parse(json: string): any {
        return JSON.parse(json);
    }
}