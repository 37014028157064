import { Injectable } from "@angular/core";
import { StorageKit } from 'yk2-core';

@Injectable()
export class MyConfig {
    constructor() {

    }

    public get baseUrl(): string {
        return clientConfig.baseUrl
    };
    
    public get apiBaseUrl(): string {
        return clientConfig.apiBaseUrl
    };

    public get appUrl(): string {
        return clientConfig.appUrl
    };

    public get appApiUrl(): string {
        return clientConfig.appApiUrl
    };

    public get wxAppUrl(): string {
        return clientConfig.wxAppUrl
    };
   

    public get appId(): string {
        return window.sessionStorage.getItem("MyConfig.appId")
    }
    public set appId(v: string) {
        window.sessionStorage.setItem("MyConfig.appId", v)
    }
    public get linker(): string {
        return clientConfig.sysInfo.linker
    }

    public get wxName(): string {
        return clientConfig.sysInfo.wxName
    }




    public aliyunOssConfig: any = {
        region: "cn-beijing",
        endpoint: "oss-cn-beijing.aliyuncs.com",
        bucket: "yunshangtest"
    };
}