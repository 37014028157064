import { Injectable } from '@angular/core';
import { StorageKit } from './storagekit';
import { TypeKit } from './typekit';
import { MyConfig } from 'app/mycore';
import * as $ from 'jquery';
import * as qs from 'qs';

@Injectable()
export class HttpKit {
    constructor(private typekit: TypeKit, private storagekit: StorageKit, private config: MyConfig) {
    }

    static json2url(obj, prefix = '') {
        var urlString = "";
        for (var key in obj) {
            if (obj[key] !== null && prefix == '' && ["string", "number"].includes(typeof (obj[key]))) {
                urlString += key + "=" + obj[key] + "&";
            }
            else if (obj[key] !== null && typeof obj[key] == "object") {
                let tmpPrefix = '';
                if (prefix == '') {
                    tmpPrefix = key;
                } else {
                    tmpPrefix = prefix + "[" + key + "]";
                }
                urlString += this.json2url(obj[key], tmpPrefix);
            } else {
                urlString += prefix + "[" + key + "]=" + (obj[key] == null ? '' : obj[key]) + "&";
            }
        }

        return urlString;
    }

    public loading: boolean = false;

    /**
     * 检查登录状态，只需要调用即可，不用考虑返回值
     */
    public checkAuth(success?: () => void) {
        this.get(this.config.apiBaseUrl + "core/tokenauth/check", {}, () => {
            if (!!success) {
                success();
            }
        }, null, null, false);
    }

    lastRefreshTime: number = 0;
    /**
     * 每次调用接口成功后执行刷新，每10分钟内执行一次
     */
    refreshAuth() {
        let now = new Date().getTime();
        if (this.lastRefreshTime == 0) {
            this.lastRefreshTime = now;
            return;
        }
        if (now - this.lastRefreshTime < 1000 * 60 * 10) {
            return;
        }
        this.lastRefreshTime = now;

        this.get(this.config.apiBaseUrl + "core/tokenauth/refresh", {}, data => {
            if (!!data.token) {
                console.log(data.token);
                this.addHeader("authorization", "basic " + data.token);
                this._headers = null;
            } else {
                this.checkAuth();
            }
        }, () => {
            this.checkAuth();
        }, 1);
    }

    _headers: any = null;
    public get headers(): any {
        if (this._headers == null) {
            this._headers = {};
            var list = this.storagekit.getSessionObject('httpkit.headers');
            if (list != null) {
                list.forEach((v) => {
                    this._headers[v.key] = v.value;
                });
            }
        }
        return this._headers
    }

    public addHeader(key: string, value: string): void {
        let list: Array<any> = this.storagekit.getSessionObject('httpkit.headers');
        if (list == null) {
            list = [];
        }
        var tmp = list.find((v) => {
            return v.key == key;
        });
        if (tmp != null) {
            tmp.value = value;
        } else {
            list.push({ key: key, value: value });
        }
        this.storagekit.setSessionObject('httpkit.headers', list);
    }

    public getToken(): void {
        console.log(window.location.href);
        var search = window.location.search;
        var p = qs.parse(search, { ignoreQueryPrefix: true });
        if (!!p && !!p.token) {
            this.addHeader("authorization", "basic " + p.token);
            console.log('token:' + p.token);
        }
    }

    /**
    *@dataType:success回调传入的参数类型，0：yk2接口数据,1：json数据，2：文本数据,99:响应对象
    **/
    public get(url: string, data: any, success: (data: any) => void, error?: (err: any) => void, returnType: number = 0, isAsync: boolean = true): void {
        this.request(url, 'get', data, success, error, 0, returnType, isAsync);
    }

    /**
    *@returnType:success回调传入的参数类型，0：yk2接口数据,1：原始数据
    **/
    public post(url: string, data: any, success: (data: any) => void, error?: (err: any) => void, returnType: number = 0, isAsync: boolean = true): void {
        this.request(url, 'post', data, success, error, 0, returnType, isAsync);
    }
    /**
    *@returnType:success回调传入的参数类型，0：yk2接口数据,1：原始数据
    **/
    public post2(url: string, data: any, success: (data: any) => void, error?: (err: any) => void, returnType: number = 0, isAsync: boolean = true): void {
        this.request(url, 'post', data, success, error, 1, returnType, isAsync);
    }

    /**
     * 
     * @param url 
     * @param method 
     * @param data 
     * @param success 
     * @param error 
     * @param dataContentType 0:application/x-www-form-urlencoded;1:post时application/json
     * @param returnType 0：yk2接口数据,1：res对象,2：文本数据
     */
    public request(url: string, method: string, data: any, success: (data: any) => void, error?: (err: any) => void, dataContentType: number = 0, returnType: number = 0, isAsync: boolean = true) {
        if (url.includes('?')) {
            url = url + '&s=' + new Date().getTime();
        } else {
            url = url + '?s=' + new Date().getTime();
        }

        let ajaxData = null;
        let contentType = '';
        if (method == 'get') {
            ajaxData = $.param(data, true);
            contentType = 'application/x-www-form-urlencoded';
        } else {
            if (dataContentType == 0) {
                ajaxData = this.typekit.Object2Json(data);
                contentType = 'application/x-www-form-urlencoded';
            } else if (dataContentType == 1) {
                ajaxData = JSON.stringify(data);
                contentType = 'application/json';
            }
        }

        let dataType = 'json';
        if (returnType == 0 || returnType == 1) {
            dataType = 'json';
        } else if (returnType == 2) {
            dataType = "text";
        }

        this.loading = true;
        $.ajax({
            url: url,
            type: method,
            data: ajaxData,
            contentType: contentType,
            async: isAsync,
            dataType: dataType,
            success: (res: any) => {
                this.loading = false;
                switch (returnType) {
                    case 0:
                        if (res.isOK) {
                            success(res.data);
                        } else {
                            if (typeof (error) == 'function') {
                                error(res.message);
                            }
                            HttpKit.errorMessageHandler(this, res.message);
                        }
                        break;
                    case 1:
                        success(res);
                        break;
                    case 2:
                        success(res);
                    default:
                        success(res);
                        break;
                }
            },
            beforeSend: (jqXHR: any) => {
                var list = this.storagekit.getSessionObject('httpkit.headers');
                if (list != null) {
                    this._headers = null;
                    list.forEach((v) => {
                        jqXHR.setRequestHeader(v.key, v.value);
                    });
                }
            },
            error: (e: any) => {
                this.loading = false;
                console.log("error:" + e);
                console.log(e.responseJSON);

                switch (e.status) {
                    case 0:
                        HttpKit.get0ErrorHandler(this, e);
                        break;
                    case 401:
                        switch (e.responseJSON.statusCode) {
                            case 1:
                                HttpKit.loginErrorHandler(this, e);
                                break;
                            case 2:
                                HttpKit.authErrorHandler(this, e);
                                break;
                            default:
                                break;
                        }

                        HttpKit.get401ErrorHandler(this, e);
                        break;
                    case 500:
                        HttpKit.get500ErrorHandler(this, e);
                        break;
                    default:
                        HttpKit.getOtherErrorHandler(this, e);
                        break;
                }
            }
        });
    }

    public static loginErrorHandler: (httpkit: HttpKit, e: any) => void = (httpkit: HttpKit, e: any) => {
        console.log('error login' + e);
    };
    public static authErrorHandler: (httpkit: HttpKit, e: any) => void = (httpkit: HttpKit, e: any) => {
        console.log('error auth' + e);
    };
    public static get401ErrorHandler: (httpkit: HttpKit, e: any) => void = (httpkit: HttpKit, e: any) => {
        console.log('error 401' + e);
    };
    public static get0ErrorHandler: (httpkit: HttpKit, e: any) => void = (httpkit: HttpKit, e: any) => {
        console.log('error 0' + e);
    };
    public static get500ErrorHandler: (httpkit: HttpKit, e: any) => void = (httpkit: HttpKit, e: any) => {
        console.log('error 500' + e);
    };
    public static getOtherErrorHandler: (httpkit: HttpKit, e: any) => void = (httpkit: HttpKit, e: any) => {
        console.log('OtherError' + e);
    };
    public static errorMessageHandler: (httpkit: HttpKit, message: string) => void = (httpkit: HttpKit, message: string) => {
        console.log(message);
    }


}
