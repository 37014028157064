import { Component, Injectable } from '@angular/core';
import { JsonKit } from './jsonkit';

@Injectable()
export class StorageKit {

    constructor(private jsonkit: JsonKit) {
    }

    public setLocal(key: string, data: string): void {
        window.localStorage.setItem(key, data);
    }
    public setLocalObject(key: string, data: any): void {
        this.setLocal(key, this.jsonkit.stringify(data));
    }

    public getLocal(key: string): string {
        return window.localStorage.getItem(key);
    }
    public getLocalObject(key: string): any {
        return this.jsonkit.parse(this.getLocal(key));
    }

    public clearLocal(): void {
        return window.localStorage.clear();
    }

    public setSession(key: string, data: string): void {
        window.sessionStorage.setItem(key, data);
    }
    public setSessionObject(key: string, data: any): void {
        this.setSession(key, this.jsonkit.stringify(data));
    }

    public getSession(key: string): string {
        return window.sessionStorage.getItem(key);
    }
    public getSessionObject(key: string): any {
        return this.jsonkit.parse(this.getSession(key));
    }

    public clearSession(): void {
        return window.sessionStorage.clear();
    }

}