import { Injectable } from '@angular/core';
import { HttpKit } from './httpkit';
import { MyConfig } from 'app/mycore';


@Injectable()
export class AliyunKit {

    constructor(private httpkit: HttpKit, private config: MyConfig) {
    }

    public doUpload(storeAs: string, file: any, options?: any, success?: (res: any) => void, fail?: (err: any) => void): void {
        let op = !options ? {} : options;

        this.httpkit.get(this.config.apiBaseUrl + "core/aliyun/ststoken/gettoken", {}, data => {
            var ossclient = new OSS({
                region: this.config.aliyunOssConfig.region,// 'cn-beijing',
                accessKeyId: data.accessKeyId,
                accessKeySecret: data.accessKeySecret,
                stsToken: data.security,
                endpoint: this.config.aliyunOssConfig.endpoint,//'oss-cn-beijing.aliyuncs.com',
                bucket: this.config.aliyunOssConfig.bucket// 'yunshangtest'
            });

        });
    }

}