export class ArrayKit {
    /**
     * 从数组array中删除符合条件的项
     * @param array 
     * @param item 
     * @param fun 
     */
    public delete(array: Array<any>, fun: (value: any) => boolean): void {
        for (let i = array.length - 1; i >= 0; i--) {
            if (fun(array[i])) {
                array.splice(i, 1);
            }
        }
    }

    /**
     * 往array的index位置插入list
     * @param array 
     * @param index 
     * @param list 
     */
    public insert(array: Array<any>, index: number, list: Array<any>) {
        array.splice(index, 0, ...list);
    }

    /**
     * 判断集合是否包含符合条件的项
     * @param array 
     * @param fun 
     */
    public includes(array: Array<any>, fun: (value: any) => boolean): boolean {
        let r: boolean = false;
        array.forEach(v => {
            if (fun(v)) {
                r = true;
            }
        });

        return r;
    }

    /**
     * 集合项类型的转换，类似c#lamda中的select
     * @param array 
     * @param transformFunction 
     */
    public select(array: Array<any>, transformFunction: (v: any) => any): Array<any> {
        let tf = transformFunction;
        let r: Array<any> = [];
        array.forEach(v => {
            r.push(tf(v));
        });

        return r;
    }

    /**
     * 对比两个集合，得出相对目标集合的added，modified，deleted，unchanged
     * @param target 
     * @param list 
     * @param compoareFunction 0 完全相等；1 id相等,内容不同；2 完全不同
     */
    public diff(target: Array<any>, list: Array<any>, compoareFunction: (v: any, v2: any) => number): { added: Array<any>, modified: Array<any>, deleted: Array<any>, unchanged: Array<any> } {
        let cf = compoareFunction;
        let targetList = [];
        target.forEach(v => {
            targetList.push(v);
        });

        let added: Array<any> = [];
        added.push(...list);
        let modified: Array<any> = [];
        let deleted: Array<any> = [];
        let unchanged: Array<any> = [];

        for (let i = targetList.length - 1; i >= 0; i--) {
            if (added.length == 0) {
                deleted.push(...targetList);
                targetList.splice(0);
                break;
            }
            for (let j = added.length - 1; j >= 0; j--) {
                var r = cf(targetList[i], added[j]);

                if (r == 0) {
                    unchanged.push(added[j]);
                    added.splice(j, 1);
                    targetList.splice(i, 1);
                    break;
                }
                else if (r == 1) {
                    modified.push(added[j]);
                    added.splice(j, 1);
                    targetList.splice(i, 1);
                    break;
                }
                else if (r == 2 && j == 0) {
                    deleted.push(targetList[i]);
                    targetList.splice(i, 1);
                    break;
                }
            }
        }
        return { added: added, modified: modified, deleted: deleted, unchanged: unchanged };
    }

}