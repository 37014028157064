import { NgModule, Optional, SkipSelf } from '@angular/core';

import { throwIfAlreadyLoaded } from './module-import-guard';

import { HttpKit } from './httpkit';
import { WebKit } from './webkit';
import { TypeKit } from './typekit';
import { StorageKit } from './storagekit';
import { ArrayKit } from './arraykit';
import { DataKit } from './datakit';
import { WeixinKit } from './weixinkit';
import { BrowserKit } from './browserkit';
import { AliyunKit } from './aliyunkit';
import { JsonKit } from './jsonkit';

@NgModule({
    imports: [
    ],
    providers: [
        HttpKit,
        WebKit,
        TypeKit,
        StorageKit,
        ArrayKit,
        DataKit,
        WeixinKit,
        BrowserKit,
        AliyunKit,
        JsonKit
    ],
    declarations: [
    ]
})
export class YK2CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: YK2CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'YK2CoreModule');
    }

}