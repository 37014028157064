import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';

@Injectable()
export class TypeKit {
    constructor() {
    }

    public isInt(s: string): boolean {
        var i = parseInt(s);
        if (isNaN(i)) {
            return false;
        }
        if (i.toString().length != s.length) {
            return false;
        }
        return true;
    }

    public toInt(s: string, v: number = 0): number {
        return this.isInt(s) ? parseInt(s) : v;
    }

    /**
     * js对象转换为json对象，注意不是json字符串
     * @param obj 
     */
    public Object2Json(obj: any): any {
        return JSON.parse(JSON.stringify(obj));
    }

    public isFloat(s: string): boolean {
        var i = parseFloat(s);
        if (isNaN(i)) {
            return false;
        }
        var dotIndex = s.indexOf('.');
        if (dotIndex < 0) {
            return this.isInt(s);
        }
        return this.isInt(s.substr(0, dotIndex)) && this.isInt('1' + s.substr(dotIndex + 1));
    }
    public toFloat(s: string, v: number = 0): number {
        return this.isFloat(s) ? parseFloat(s) : v;
    }

    public isDate(s: string, format: string = 'Y-M-D'): boolean {
        return dayjs(s, format).isValid()
    }
    public isDateTime(s: string, format: string = 'Y-M-D H:m:s'): boolean {
        return dayjs(s, format).isValid()
    }

    /**
     * 对象是否为空，对象为null或undefined时都返回true
     * @param obj 
     */
    public isNull(obj: any): boolean {
        if (typeof obj == 'undefined') {
            return true;
        }
        if (obj == null) {
            return true;
        }
        return false;
    }
    /**
     * 对象是否是实例化的对象，不能为null或undefined
     * @param obj 
     */
    public isObject(obj: any): boolean {
        return !this.isNull(obj);
    }
    /**
     * 
     * @param obj 是否未定义
     */
    public isUndefined(obj: any): boolean {
        return typeof obj == 'undefined';
    }

    /**
     * 获取时间戳字符串
     */
    public getTimestamp(): string {
        return new Date().getTime().toString();
    }
}