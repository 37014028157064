import { Injectable } from '@angular/core';
import { StorageKit } from './storagekit';
import { HttpKit } from './httpkit';
import { ArrayKit } from './arraykit';
import { BrowserKit } from './browserkit';
import { MyConfig } from 'app/mycore';


@Injectable()
export class WeixinKit {
    constructor(private httpkit: HttpKit, private storagekit: StorageKit, private config: MyConfig, private arraykit: ArrayKit, private browserkit: BrowserKit) {
    }

    public JsSdkConfig(apis: string[], ready?: () => void, error?: (res: any) => void) {
        let url = '';

        if (!this.browserkit.browserType.ios) {
            url = window.location.href;
        } else {
            url = window.initHref;
        }
        url = url.split('#')[0];

        let jsSdkConfigs = this.storagekit.getSessionObject("JsSdkConfigs");
        if (!jsSdkConfigs) {
            jsSdkConfigs = {
            };
        }
        if (!jsSdkConfigs[url]) {
            jsSdkConfigs[url] = {
                timestamp: 0,
                jsApiList: []
            };
        }
        let c = jsSdkConfigs[url];

        let diff = this.arraykit.diff(c.jsApiList, apis, (v, v2) => {
            return v == v2 ? 0 : 2;
        });

        let timespan = new Date().getTime() - c.timestamp;
        if (timespan < 1000 * 60 * 5 && diff.added.length == 0) {
            if (!!ready) {
                ready();
            }
            return;
        }

        this.httpkit.get(this.config.apiBaseUrl + "core/weixin/GetJsSdkConfig", { url: url }, (data) => {

            wx.config({
                debug: data.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: data.appId, // 必填，公众号的唯一标识
                timestamp: data.timestamp, // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.signature,// 必填，签名
                jsApiList: diff.added // 必填，需要使用的JS接口列表
            });

        });

        let that = this;
        wx.ready(function () {
            c.jsApiList.push(...diff.added);
            c.timestamp = new Date().getTime();
            jsSdkConfigs[url] = c;
            that.storagekit.setSessionObject("JsSdkConfigs", jsSdkConfigs);

            if (!!ready) {
                ready();
            }
        });
        wx.error(function (res) {
            if (!!error) {
                alert(JSON.stringify(res));
                error(res);
            }
        });
    }
}