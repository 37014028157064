import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SafePipe } from './pipes/safe.pipe';

// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    providers: [
    ],
    declarations: [
        SafePipe
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        SafePipe
    ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class YK2SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: YK2SharedModule
        };
    }
}
