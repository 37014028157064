import { Component } from '@angular/core';
import { HttpKit, WebKit } from 'yk2-core';
import { MyConfig } from './mycore';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [WebKit]
})
export class AppComponent {
  constructor(private myconfig: MyConfig, private httpkit: HttpKit, private webkit: WebKit) {

    HttpKit.loginErrorHandler = (httpkit: HttpKit, e: any) => {
      window.location.href = this.myconfig.apiBaseUrl + "core/weixin/GetOpenId?backurl=" + escape(window.location.href);
    };
    this.httpkit.getToken();
    this.httpkit.checkAuth();

  }
}
