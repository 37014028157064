import { Injectable } from '@angular/core';

@Injectable()
export class DataKit {
    constructor() {
    }

    powers=new Array("7","9","10","5","8","4","2","1","6","3","7","9","10","5","8","4","2");   
    parityBit=new Array("1","0","X","9","8","7","6","5","4","3","2");   
    public sex="male";
    public birthday="";
    public message="";
    //校验身份证号码的主调用 

    public validId(idcard):boolean{
        console.log()
        var _id=idcard;   
        if(_id=="")return false ;   
        var _valid=false;   
        if(_id.length==15){   
             _valid=this.validId15(_id);   
         }else if(_id.length==18){   
             console.log(_id);
             _valid=this.validId18(_id);   
         }   
        if(!_valid){   
             this.message="身份证号码有误,请检查!";   
            return false;   
         } 
         return true;   
    }

    //校验18位的身份证号码   
    public validId18(_id):boolean{
        _id=_id+"";   
        var _num=_id.substr(0,17);   
        var _parityBit=_id.substr(17);   
        var _birthday=_id.substr(6,8);
        console.log(_num+"--"+_parityBit+"--"+_birthday);
        var _power=0;   
        for(var i=0;i< 17;i++){   
            //校验每一位的合法性   
  
            if(_num.charAt(i)<'0'||_num.charAt(i)>'9'){   
                console.log("char=="+i);
                return false;   
             }else{   
                //加权   
  
                 _power+=parseInt(_num.charAt(i))*parseInt(this.powers[i]);   
                //设置性别   
  
                if(i==16&&parseInt(_num.charAt(i))%2==0){   
                     this.sex="female";   
                 }else{   
                     this.sex="male";   
                 }   
             }   
         }   
        //取模   
        this.birthday=_birthday;
        var mod=_power%11;   
        if(this.parityBit[mod]==_parityBit){   
            return true;   
         }   
        return false;   
    }

    //校验15位的身份证号码   
  
    public  validId15(_id):boolean{   
        _id=_id+"";   
       for(var i=0;i<_id.length;i++){   
           //校验每一位的合法性   
 
           if(_id.charAt(i)<'0'||_id.charAt(i)>'9'){   
               return false;  
            }   
        }   
       var year=_id.substr(6,2);   
       var month=_id.substr(8,2);   
       var day=_id.substr(10,2);   
       var sexBit=_id.substr(14);   
       //校验年份位   
 
       if(year<'01'||year >'90')return false;   
       //校验月份   
 
       if(month<'01'||month >'12')return false;   
       //校验日   
 
       if(day<'01'||day >'31')return false;   
       //设置性别   
       if(sexBit%2==0){   
            this.sex="female";   
        }else{   
            this.sex="male";   
        }   
       return true;   
    }   

    
    /**
     * 获取时间戳字符串
     */
    public getTimestamp(): string {
        return new Date().getTime().toString();
    }
    /**
     * 
     * @param mobile 校验手机号
     */
    public isValidMobile(mobile: string): boolean {
        let phoneReg = /^1[3-578]\d{9}$/;
        if (phoneReg.test(mobile)) {
            return true;
        } else {
            return false;
        }
    }
    /**
     * 
     * @param email 校验邮箱 
     * 
     */
    public isValidEMail(email: string): boolean {
        let mailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
        if (mailReg.test(email)) {
            return true;
        } else {
            return false;
        }
    }


}