import { NgModule, Optional, SkipSelf } from '@angular/core'; 
import { MyConfig } from './myconfig';
import { throwIfAlreadyLoaded } from 'yk2-core';

@NgModule({
  providers: [
    MyConfig
  ],
})
export class MyCoreModule {
  constructor(@Optional() @SkipSelf() parentModule: MyCoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
