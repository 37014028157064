import { Component, OnInit, Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras, Params } from '@angular/router';
import { HttpKit } from './httpkit';

//web调用，导航、获取参数 
@Injectable()
export class WebKit {

    constructor(private router?: Router, private route?: ActivatedRoute) {
    }
    public get CurrentUrl(): string {
        return this.router != null ? this.router.url : "";
    }

    /**
     * 导入import { WebKit } from '../../common/index';
     * 在componnent中添加providers: [WebKit]，并在构造函数中添加private webkit: WebKit
     * this.webkit.navigate("../user-detail", ['aaa']);
     * @param url
     * @param params
     */
    // public navigate2(url: string, params: any[] = []): void {
    //     let navParams: any[] = new Array();
    //     navParams.push(url);
    //     params.forEach((value: any, index: number, array: any[]) => {
    //         navParams.push(value);
    //     });
    //     this.router.navigate(navParams, { relativeTo: this.route });
    // }

    public navigate(url: string, params?: any): void {
        this.router.navigate([url], {
            queryParams: params
        });
    }

    // public queryParams2(key: string): any {
    //     return this.route.snapshot.params[key];
    // }
    /**
     * 导入import { WebKit } from '../../common/index';
     * 在componnent中添加providers: [WebKit]，并在构造函数中添加private webkit: WebKit
     * this.webkit.queryParams("aaa")
     * @param key
     */
    public queryParams(key: string): any {
        let v: any;
        this.route.queryParams.subscribe(queryParams => {
            v = queryParams[key];
        });
        if (!v) {
            v = this.route.snapshot.params[key];
        }
        return v;
    }

    public onRouterEvents(callback: (e: any) => void): void {
        this.router.events.subscribe(event => {
            callback(event);
        });
    }



}